import { useCallback, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

// Define mutation
const GQL = gql`
  mutation CreateLeads($input: CreateLeadsInput!) {
    createLeads(input: $input)
  }
`;

const useCreateLeads = () => {
  const [submit, { data, loading, error }] = useMutation(GQL);
  const onSubmit = useCallback(
    (values: any) => {
      try {
        submit({
          variables: {
            input: values
          }
        });
      } catch (error) {
        toast.error('Message sent failed');
      }
    },
    [submit]
  );

  useEffect(() => {
    if (data && !loading && !error) {
      toast.success(
        'Your message has been sent successfully. We shall get back to you soon!'
      );
      return;
    }
    if (error) {
      toast.error('Message sent failed');
    }
  }, [data, loading, error]);

  return { onSubmit, loading };
};

export default useCreateLeads;
