import React from 'react';
import cx from 'classnames';
interface IProps {
  className?: string;
  children: React.ReactNode;
}

const Content: React.FC<IProps> = ({ className, children }) => {
  return <div className={cx('mt-10 lg:mt-20', className)}>{children}</div>;
};

export default Content;
