import React from 'react';
import cx from 'classnames';
interface IProps {
  className?: string;
  children: React.ReactNode;
}

const Paragraph: React.FC<IProps> = ({ className, children }) => {
  return (
    <p
      className={cx(
        'text-sm font-light sm:text-lg sm:leading-[26px] lg:text-base xl:text-xl xl:leading-10',
        className
      )}
    >
      {children}
    </p>
  );
};

export default Paragraph;
