import React from 'react';
import cx from 'classnames';
interface IProps {
  className?: string;
  children: React.ReactNode;
}

const SubHead: React.FC<IProps> = ({ className, children }) => {
  return (
    <div className={cx('font-light xl:text-[22px]', className)}>{children}</div>
  );
};

export default SubHead;
