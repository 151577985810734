import React from 'react';
import cx from 'classnames';
interface IProps {
  className?: string;
  children: React.ReactNode;
}

const Card: React.FC<IProps> = ({ className, children }) => {
  return (
    <div
      className={cx(
        'relative rounded-3xl border border-sky/50 bg-darker/40 p-6 sm:p-8 lg:rounded-[72px] lg:p-16',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Card;
