import React from 'react';
import cx from 'classnames';
import Button from '@/components/button';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

const Cta: React.FC<IProps> = ({ className, children, ...rest }) => {
  return (
    <Button
      className={cx(
        'shadow-gradient mt-10 bg-gradient-to-r from-blue via-brown to-orange px-14 lg:mt-20',
        className
      )}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default Cta;
