import React from 'react';
import cx from 'classnames';
interface IProps {
  className?: string;
  children: React.ReactNode;
  [key: string]: any;
}

const Section: React.FC<IProps> = ({ className, children, ...props }) => {
  return (
    <section className={cx('relative pt-20 lg:pt-40', className)} {...props}>
      {children}
    </section>
  );
};

export default Section;
