import React from 'react';
import cx from 'classnames';

interface Iprops {
  text: string;
  wrapperClassName?: string;
  className?: string;
  subTitle?: string;
}

const Title: React.FC<Iprops> = ({
  text,
  className,
  wrapperClassName,
  subTitle
}) => {
  return (
    <div className={wrapperClassName}>
      <h3
        className={cx(
          'text-2xl tracking-tighter text-center sm:text-[40px] sm:leading-[42px] lg:text-[48px] lg:leading-[70px] xl:text-[60px]',
          className
        )}
      >
        {text}
      </h3>
      {subTitle && (
        <p className="text-balance mt-4 text-center text-sm font-medium lg:text-xl lg:leading-[36px]">
          {subTitle}
        </p>
      )}
    </div>
  );
};

export default Title;
