import { FC, useEffect, useState } from 'react';
import useShowMore from '@/utils/useShowMore';
import Button from './button';
import Video from './video';
import { useResponsive } from '@/utils/useResponsive';
import { VIDEO_URL } from '@/config-global';

const text =
  'Nestflo AI-powered virtual robot creates new business opportunities, saves time, automates tasks and empowers fast response times for estate agencies.';

const Hero: FC = () => {
  const [limit, setLimit] = useState(0);
  const { toggle, isMore } = useShowMore(text, limit);
  const { isMobile } = useResponsive();

  useEffect(() => {
    if (isMobile) {
      setLimit(40);
    } else {
      setLimit(70);
    }
  }, [isMobile]);

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative">
        <picture className="pointer-events-none absolute inset-0 -z-20 overflow-hidden">
          <img
            src="/assets/background/bg_hero_375.png"
            alt="dark"
            className="h-auto w-full sm:hidden"
          />
          <video autoPlay loop muted className="hidden h-auto w-full sm:block">
            <source src="/assets/video/bg_hero.mp4" type="video/mp4" />
          </video>
          {/* <img
            src="/assets/background/bg_hero_375.png"
            alt="dark"
            className="h-auto w-full sm:hidden"
          />
          <img
            src="/assets/background/bg_hero.png"
            alt="dark"
            className="hidden h-auto w-full sm:block"
          /> */}
        </picture>
        <div className="container pb-20 pt-16 xl:pt-32">
          <div className="mx-auto max-w-6xl pt-20 lg:pt-36">
            <div className="lg:w-4/5">
              <h1 className="font-display mb-10 text-4xl font-light leading-[50px] tracking-tighter text-white sm:text-[40px] md:text-[50px] lg:text-6xl lg:leading-[70px]">
                Boost profit through AI-powered advanced client automation for
                estate agencies
              </h1>
              <p className="text-sm font-medium leading-[28px] tracking-tighter sm:text-base lg:text-base">
                <span className="align-bottom">{text}</span>
                <span
                  className="ml-2 hidden cursor-pointer align-bottom font-medium text-orange"
                  onClick={toggle}
                >
                  {isMore ? 'Show less' : 'Show more'}
                </span>
              </p>
              <div className="flex items-center justify-center lg:justify-start">
                <Video
                  target={
                    <Button className="shadow-gradient mt-24 bg-gradient-to-r from-blue via-brown to-orange px-8">
                      Watch video story
                    </Button>
                  }
                  url={VIDEO_URL}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero;
