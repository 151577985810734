import React from 'react';
import cx from 'classnames';
interface IProps {
  className?: string;
  children: React.ReactNode;
}

const Contain: React.FC<IProps> = ({ className, children }) => {
  return (
    <div className={cx('container mx-auto w-full', className)}>{children}</div>
  );
};

export default Contain;
