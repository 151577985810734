import React, { forwardRef } from 'react';
import Tippy from '@tippyjs/react';

/**
 * @see https://github.com/atomiks/tippyjs-react#component-children
 */
interface ButtonProps {
  children?: React.ReactNode;
}
export type Ref = HTMLButtonElement;

const Target = forwardRef<Ref, ButtonProps>((props, ref) => {
  return <button ref={ref}>{props.children}</button>;
});
Target.displayName = 'Target';

interface MyTippyProps {
  children?: React.ReactNode;
  [key: string]: any;
}
const MyTippy: React.FC<MyTippyProps> = ({ children, ...props }) => {
  return (
    <Tippy {...props}>
      <Target>{children}</Target>
    </Tippy>
  );
};

export default MyTippy;
