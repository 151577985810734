import { useMediaQuery } from 'react-responsive';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(() => import('react-responsive'), {
  ssr: false
});
const useResponsive = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
  const isLargeDesktop = useMediaQuery({ query: '(min-width: 1671px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1199px)' });
  const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 992px)' });

  return {
    isDesktop,
    isLargeDesktop,
    isMobile,
    isTablet,
    isTabletOrDesktop,
    isTabletOrMobile
  };
};

export { MediaQuery as default, useResponsive };
