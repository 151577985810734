import React, { useEffect, useState } from 'react';
import { useResponsive } from '@/utils/useResponsive';
import { InvestShadow } from '@/assets/shadow';
import { Container, Section } from '@/components/section';
interface dataProps {
  description: string;
  logo: string;
  position: string;
}

const data: dataProps[] = [
  {
    description:
      'Funded by Innovate UK Edge to help Nestflo realise the innovation potential and achieve business goals.',
    logo: '/assets/background/logo_ukri.svg',
    position: 'left-0 top-0 w-[160px] sm:w-[204px] lg:w-[248px] h-auto'
  },
  {
    description:
      'Funded by the European Regional Development Fund and managed by the University of West England (UWE)',
    logo: '/assets/background/logo_uwe.png',
    position: 'right-0 top-0 w-[100px] sm:w-[125px] lg:w-[150px] h-auto'
  },
  {
    description: 'Funded by HM Government & West of England Combined Authority',
    logo: '/assets/background/logo_west.png',
    position: 'left-0 bottom-0 w-[120px] sm:w-[140px] lg:w-[160px] h-auto'
  },
  {
    description:
      'Funded by the European Regional Development Fund and managed by the West of England Combined Authority',
    logo: '/assets/background/logo_west.png',
    position: 'right-0 bottom-0 w-[130px] w-[156px] lg:w-[182px] h-auto'
  }
];

const Invest: React.FC = () => {
  const [bg, setBg] = useState('');

  const { isMobile } = useResponsive();

  useEffect(() => {
    if (isMobile) {
      setBg('/assets/background/bg_invest_375_bk.png');
    } else {
      setBg('/assets/background/bg_invest_full_bk.png');
    }
  }, [isMobile]);

  return (
    <Section>
      <picture className="pointer-events-none absolute inset-x-0 top-[-300px] -z-20">
        <InvestShadow className="h-auto w-full" />
      </picture>
      <Container className="max-w-[1499px]">
        <picture className="pointer-events-none">
          <img
            src={bg}
            alt="dark"
            className="-mx-4 h-auto w-full opacity-20 lg:mx-0"
          />
        </picture>
        <h3 className="vertical-center sm:total-center absolute w-1/2 text-5xl font-medium tracking-tighter sm:text-center lg:w-full lg:text-[65px]">
          We&apos;re backed by
        </h3>
        <div
          className="total-center absolute flex h-[630px] w-full max-w-[890px] flex-col p-6 lg:p-0"
          id="link_3"
        >
          <div className="relative h-full">
            {data.map((item, index) => (
              <div key={index} className={`absolute ${item.position}`}>
                <img src={item.logo} alt={item.description} />
                <p className="mt-4 text-xs font-light lg:text-sm">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Invest;
