import React from 'react';
import { Container, Content, Cta, Section, Title } from './section';
import { MagnetIcon, RocketIcon, TimeIcon, TradeIcon } from '@/assets/icons';
import Button from './button';
import Video from './video';
import { FeatureShadow } from '@/assets/shadow';
import useShowMore from '@/utils/useShowMore';
import { Card, Head, Paragraph } from '@/components/card';
import { VIDEO_URL } from '@/config-global';
import Link from 'next/link';
interface ItemProps {
  description: string;
  icon: React.ReactNode;
  tag: string;
  title: string | React.ReactNode;
}

const Item: React.FC<{ item: ItemProps }> = ({ item }) => {
  const { toggle, isMore } = useShowMore(item.description, 100);
  return (
    <Card>
      <div className="mb-8 flex items-center">
        {item.icon}
        <Button className="gradient-button-bg-1 px-4 text-sm font-light tracking-[-0.065em] sm:px-8 sm:py-3 sm:text-left lg:whitespace-nowrap lg:px-8 lg:text-center lg:text-[20px] xl:text-[26px]">
          {item.tag}
        </Button>
      </div>
      <Head className="mt-4">{item.title}</Head>
      <Paragraph className="mt-6">
        {/* {str} */}
        {item.description}
      </Paragraph>
      <div className="mt-6 hidden w-full items-center justify-end">
        <button
          className="leading-0 cursor-pointer align-bottom font-medium text-orange"
          onClick={toggle}
        >
          {isMore ? 'Show less' : 'Show more'}
        </button>
      </div>
    </Card>
  );
};

const Feature: React.FC = () => {
  const data: ItemProps[] = [
    {
      description:
        'By automating the back-office tasks that do not require specialist skill or knowledge can save a letting negotiator up to four hours a day. This amounts to 1248 hours a year. Based on the average cost of £20 per hour, this would result in an annual saving of £24,960, equivalent to approximately £2,000 per month.',
      icon: <TradeIcon className="mr-6 w-8 shrink-0 lg:w-14" />,
      tag: 'Save money',
      title: <span>Save up to £2,000 per month</span>
    },
    {
      description:
        'Save the time your staff spends on manually responding to and screening prospective tenants. This will free up their bandwidth to create additional business opportunities, which can, in turn, increase revenue.',
      icon: <TimeIcon className="mr-6 w-8 shrink-0 lg:w-14" />,
      tag: 'More efficient',
      title: (
        <span>
          Create time for <br />
          high value tasks
        </span>
      )
    },
    {
      description: `Offer immediate replies around the clock, every day of the week, to all inquiries through accurate and insightful conversations. By addressing all prospective customer inquiries without fail, your business's reputation will significantly improve.`,
      icon: <MagnetIcon className="mr-6 w-8 shrink-0 lg:w-14" />,
      tag: 'Enhance tenant engagement',
      title: <span>Enhanced customer services with 24/7 instant response</span>
    },
    {
      description:
        'Make accurate, automatic recommendations based on intelligent AI conversations. You’ll match tenants with suitable properties far more efficiently.',
      icon: <RocketIcon className="mr-6 w-8 shrink-0 lg:w-14" />,
      tag: 'Accelerate the letting process',
      title: (
        <span>
          Automatically matching properties with tenants to accelerate the
          process
        </span>
      )
    }
  ];

  return (
    <Section id="link_1">
      <picture className="pointer-events-none absolute inset-x-0 top-[-300px] -z-20">
        <FeatureShadow className="h-auto w-full" />
      </picture>
      <Container className="max-w-[1467px]">
        <Title
          text="Free up precious time to boost your business's profit"
          className="mx-auto max-w-4xl font-light"
        />
        <Content>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-5 md:gap-12">
            {data.map((item, index) => (
              <Item item={item} key={index} />
            ))}
          </div>
          <div className="flex w-full flex-col items-center">
            <Link
              href="https://calendly.com/roland-tao-55/30min?month=2024-02"
              target="_blank"
            >
              <Cta>Book a demo</Cta>
            </Link>
          </div>
        </Content>
      </Container>
    </Section>
  );
};

export default Feature;
