import React from 'react';
import cx from 'classnames';
import { HowWorksShadow } from '@/assets/shadow';
import { PrizeIcon } from '@/assets/icons';
import { Container, Section } from '@/components/section';
interface dataProps {
  title: string;
  content: string;
  logo: string;
}

const data: dataProps[] = [
  {
    title: 'Innovate UK',
    content:
      'Recipient of Innovate UK Edge grant from Innovate UK Part of UK Research and Innovation',
    logo: '/assets/background/logo_ukri.svg'
  },
  {
    title: 'Business Innovation Fund, BIF',
    content:
      'Recipient of Business Innovation Fund grant from West of England Combined Authority',
    logo: '/assets/background/logo_west.png'
  },
  {
    title: 'Digital Innovation Fund, Dif',
    content:
      'Recipient of Digital Innovation Fund grant from University of the West of England (UWE)',
    logo: '/assets/background/logo_uwe.png'
  },
  {
    title: 'Scale Up for Growth, S4G',
    content: 'Recipient of Scale Up for Growth grant from UWE',
    logo: '/assets/background/logo_uwe.png'
  },
  {
    title: 'Business Growth Grant',
    content:
      'Recipient of Business Growth Grant from West of England Combined Authority',
    logo: '/assets/background/logo_west.png'
  }
];

const Millstone: React.FC = () => {
  return (
    <Section>
      <picture className="pointer-events-none absolute inset-x-0 -z-20">
        <HowWorksShadow className="h-auto w-full opacity-30" />
      </picture>
      <div className="container mb-6 max-w-[665px] lg:max-w-[900px]">
        <div className="relative flex items-end">
          <h3 className="w-[80%] text-2xl font-medium sm:w-[70%] lg:w-[60%] lg:text-5xl">
            We are recipients of multiple awards which demonstrates our
            credibility.
          </h3>
          <PrizeIcon className="absolute bottom-0 right-[20%] ml-5 w-7 shrink-0 lg:right-[30%] lg:w-12" />
        </div>
      </div>
      <Container className="max-w-[665px] px-8">
        <ul className="">
          {data.map((item, index) => (
            <li key={index} className="relative -mt-px flex">
              <div className="relative flex w-1/5 flex-col lg:w-1/4">
                {index % 2 != 0 && (
                  <>
                    <div className="relative w-full flex-1 rounded-tl-[25%] border-l border-t border-l-orange border-t-orange lg:rounded-tl-[100%]">
                      <div className="absolute bottom-[-6px] left-[-6px] h-3 w-3 rounded-full bg-white outline outline-1 outline-offset-[21px] outline-orange lg:-bottom-3 lg:-left-3 lg:h-6 lg:w-6 lg:outline-offset-[36px]"></div>
                    </div>
                    <div
                      className={cx(
                        'w-full flex-1 rounded-bl-[25%] lg:rounded-bl-[100%] ',
                        {
                          'border-b border-l border-b-orange border-l-orange':
                            index < data.length - 1
                        }
                      )}
                    ></div>
                    <div className="absolute inset-y-0 left-[-200%] hidden items-center justify-center lg:flex">
                      <div className="flex h-[180px] w-[180px] flex-col items-center justify-center rounded-[20px] border border-purpleLight/40 bg-darker/50">
                        <img
                          src={item.logo}
                          alt={item.title}
                          className="h-auto w-[100px]"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className={cx('flex-1 py-12 lg:py-28', {
                  'border-b border-b-orange': index < data.length - 1
                })}
              >
                <div className="relative h-[120px]">
                  <div
                    className={cx(
                      'absolute inset-y-0 flex flex-col justify-center',
                      {
                        'left-0 text-left': index % 2 != 0,
                        'right-0 text-right items-end': index % 2 === 0
                      }
                    )}
                  >
                    <div className="mb-1 flex h-[72px] w-[72px] flex-col items-center justify-center rounded-[10px] border border-purpleLight/40 bg-darker/50 p-2 lg:hidden">
                      <img
                        src={item.logo}
                        alt={item.title}
                        className="h-auto w-full"
                      />
                    </div>
                    <h4 className="mb-3 text-xl font-medium lg:whitespace-nowrap lg:text-[38px]">
                      {item.title}
                    </h4>
                    <p className="text-xs font-light text-skyLight lg:text-base">
                      {item.content}
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative flex w-1/5 flex-col lg:w-1/4">
                {index % 2 == 0 && (
                  <>
                    <div className="relative w-full flex-1 rounded-tr-[25%] border-r border-t border-r-orange border-t-orange lg:rounded-tr-[100%]">
                      <div className="absolute bottom-[-6px] right-[-6px] h-3 w-3 rounded-full bg-white outline outline-1 outline-offset-[21px] outline-orange lg:-bottom-3 lg:-right-3 lg:h-6 lg:w-6 lg:outline-offset-[36px]"></div>
                    </div>
                    <div
                      className={cx(
                        'w-full flex-1 rounded-br-[25%] lg:rounded-br-[100%]',
                        {
                          'border-b  border-r border-b-orange border-r-orange':
                            index < data.length - 1
                        }
                      )}
                    ></div>
                    <div className="absolute inset-y-0 right-[-200%] hidden items-center justify-center lg:flex">
                      <div className="flex h-[180px] w-[180px] flex-col items-center justify-center rounded-[20px] border border-purpleLight/40 bg-darker/50">
                        <img
                          src={item.logo}
                          alt={item.title}
                          className="h-auto w-[100px]"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </Section>
  );
};

export default Millstone;
