import { cloneElement, FC, useState } from 'react';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import { CircularProgress } from '@mui/material';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    background: 'black',
    transform: 'translate(-50%, -50%)',
    borderColor: 'transparent',
    padding: 0,
    overflow: 'visible'
  },
  overlay: {
    zIndex: 9999,
    background: 'rgba(0, 0, 0, 0.8)'
  }
};

Modal.setAppElement('#js-modal');

interface IProps {
  target: React.ReactElement;
  url: string;
}

const Video: FC<IProps> = ({ target, url }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [isReady, setReady] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function afterOpenModal() {
    setPlaying(true);
  }

  return (
    <>
      {cloneElement(target, { onClick: openModal })}
      <Modal
        isOpen={modalIsOpen}
        onAfterClose={() => setReady(false)}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={{
          ...customStyles,
          ...(isReady ? {} : { width: '100px', height: '100px' })
        }}
      >
        <button
          onClick={() => {
            closeModal();
          }}
          style={isReady ? {} : { display: 'none' }}
          className="hover:text-gray-300 absolute -top-8 right-0 size-6 text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {isReady ? null : <CircularProgress />}
        <div className="ratio ratio-16x9" style={{ opacity: isReady ? 1 : 0 }}>
          <ReactPlayer
            playing={playing}
            url={url}
            controls
            width="100%"
            height={isReady ? 540 : 2}
            onReady={() => setReady(true)}
          />
        </div>
      </Modal>
    </>
  );
};

export default Video;
