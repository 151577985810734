/* eslint-disable prettier/prettier */
import React, { FC, useCallback, useState } from 'react';
import { MinusCircleIcon, PlusCircleIcon } from '@/assets/icons';

const PRICES = ['£0', '£265', '£465'];
interface Iprops {
  index: number;
}

const Counter: FC<Iprops> = ({ index = 0 }) => {
  const [count, setCount] = useState(0);
  const handleIncrement = useCallback(() => {
    setCount((prev) => prev + 1);
  }, []);
  const handleDecrement = useCallback(() => {
    setCount((prev) => prev - 1);
  }, []);
  return (
    <div className="space-y-2">
      <div className="flex items-center space-x-2">
        {count > 0 && (
          <button onClick={handleDecrement}>
            <MinusCircleIcon className="h-4 w-4 font-light lg:h-6 lg:w-6" />
          </button>
        )}
        {count < 1 && (
          <button onClick={handleIncrement}>
            <PlusCircleIcon className="h-4 w-4 font-light lg:h-6 lg:w-6" />
          </button>
        )}

        <span className="text-sm">
          {count > 0 ? 'Remove sale portal' : 'Add sale protal'}
        </span>
      </div>
      {count > 0 && (
        <p className="text-center text-sm lg:text-base">{`Total ${PRICES[index]} per month`}</p>
      )}
    </div>
  );
};

export default Counter;
