import Hero from '@/components/hero';
import Feature from '@/components/feature';
import Line from '@/components/line';
import Process from '@/components/process';
import Example from '@/components/example';
import Invest from '@/components/invest';
import Work from '@/components/work';
import Price from '@/components/priceV1';
import Contact from '@/components/contact';
import Millstone from '@/components/millstone';
import Start from '@/components/start';
import Logos from '@/components/logos';

export default function Home() {
  return (
    <>
      <Hero />
      <Logos />
      <Line variant="small" />
      <Feature />
      <Line variant="medium" />
      <Process />
      <Example />
      <Invest />
      <Line variant="medium" />
      <Work />
      <Price />
      <Start />
      <Contact />
      <Millstone />
    </>
  );
}
