import React from 'react';
import cx from 'classnames';
interface IProps {
  className?: string;
  children: React.ReactNode;
}

const Head: React.FC<IProps> = ({ className, children }) => {
  return (
    <div
      className={cx(
        'text-xl font-light sm:text-[30px] sm:leading-[40px] lg:text-[32px] xl:text-[40px] xl:leading-[56px]',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Head;
