import { useCallback, useEffect, useState } from 'react';
import truncate from 'lodash/truncate';

const useShowMore = (
  text: string,
  length: number
): {
  showMore: () => void;
  str: string;
  showLess: () => void;
  isMore: boolean;
  toggle: () => void;
} => {
  const [str, setStr] = useState('');
  const [isMore, setIsMore] = useState(false);
  const showMore = useCallback(() => {
    setStr(text);
    setIsMore(true);
  }, [text]);
  const showLess = useCallback(() => {
    setStr(truncate(text, { length }));
    setIsMore(false);
  }, [text, length]);
  const toggle = useCallback(() => {
    if (isMore) {
      showLess();
    } else {
      showMore();
    }
  }, [isMore, showMore, showLess]);

  useEffect(() => {
    if (text.length > length) {
      setStr(truncate(text, { length }));
    }
  }, [text, length]);

  return { isMore, showLess, showMore, str, toggle };
};

export default useShowMore;
