import React from 'react';
import { Container, Content, Section, Title } from './section';
import { CardIcon, DataIcon, LockIcon } from '@/assets/icons';
import { ProcessShadow } from '@/assets/shadow';
interface DataOneProps {
  title: string;
  description: string;
  button?: string;
}

interface DataTwoProps {
  title: React.ReactNode;
  icon: React.ReactNode;
}

const ItemOne: React.FC<{ item: DataOneProps; index: number }> = ({
  item,
  index
}) => {
  return (
    <div className="border-darkslateblue box-border flex w-full flex-col items-start justify-start gap-[32.6px] rounded-[72px]  bg-dark/40 pb-[84px] pl-[34px] pr-5 pt-7 text-left font-poppins text-[50px] leading-[normal] tracking-[normal] text-white mix-blend-normal">
      <div className="box-border flex w-20 flex-row items-start justify-start px-1 py-0">
        <div className="gradient-button-bg-1 flex size-20 items-center justify-center rounded-full p-[20px]">
          <div className="relative flex-1 font-light leading-[20px] tracking-[-1.3px]">
            {`0${index + 1}`}
          </div>
        </div>
      </div>
      <div className="relative inline-block max-w-full text-3xl font-light leading-[40px] tracking-[-0.1px]">
        {item.title}
      </div>
      <div className="relative text-[16.9px] font-light leading-[24px] tracking-[-0.1px]">
        {item.description}
      </div>
      {item.button && (
        <div className="relative text-center text-[16.9px] font-light leading-[24px] tracking-[-0.1px]">
          <button
            onClick={() => {
              //暂时屏蔽 未上线
              window.open('https://office.nestflo.ai/auth/register', '_blank');
            }}
            style={{ textDecoration: 'underline' }}
          >
            {item.button}
          </button>
        </div>
      )}
    </div>
  );
};

const ItemTwo: React.FC<{ item: DataTwoProps; index: number }> = ({
  item,
  index
}) => {
  return (
    <div
      className="table-bg flex items-center rounded-3xl p-4 lg:justify-center xl:p-6"
      key={index}
    >
      <div className="flex items-center">
        {item.icon}
        <h4 className="font-light leading-[27px] sm:text-lg lg:text-base xl:text-lg">
          {item.title}
        </h4>
      </div>
    </div>
  );
};

const Process: React.FC = () => {
  const dataOne: DataOneProps[] = [
    {
      title: 'Register an account',
      description:
        'Simply register an account for Free by clicking ‘Free trial’ button',
      button: 'Free trial'
    },
    {
      title: 'Inform the Property Portals',
      description:
        'Inform the portals (Rightmove, Zoopla, OnTheMarket etc.) to start using a new delegated email for receiving leads'
    },
    {
      title: 'We are ready to launch.',
      description: ''
    }
  ];
  const dataTwo: DataTwoProps[] = [
    {
      title: (
        <div>
          Try Nestflo <span className="font-bold">free</span> for 2 weeks with
          no contract
        </div>
      ),
      icon: <LockIcon className="mr-6 size-16" />
    },
    {
      title: (
        <div>
          <span className="font-bold">No credit card</span> needed to start
          using Nestflo
        </div>
      ),
      icon: <DataIcon className="mr-6 size-16" />
    },
    {
      title: (
        <div>
          It is <span className="font-bold">your data,</span> and you own all
          the data
        </div>
      ),
      icon: <CardIcon className="mr-6 size-16" />
    }
  ];
  return (
    <Section id="js-start">
      <picture className="pointer-events-none absolute inset-x-0 top-[-600px] -z-20 opacity-60">
        <ProcessShadow className="h-auto w-full" />
      </picture>
      <Container>
        <Title
          text="How to quickly start using Nestflo?"
          className="mx-auto w-full max-w-screen-lg font-medium"
        />
        <Content>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3 lg:gap-8">
            {dataOne.map((item, index) => (
              <ItemOne item={item} index={index} key={index} />
            ))}
          </div>
          <div className="mx-auto mt-8 w-full max-w-screen-lg lg:mt-20">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3 lg:gap-8">
              {dataTwo.map((item, index) => (
                <ItemTwo item={item} index={index} key={index} />
              ))}
            </div>
          </div>
        </Content>
      </Container>
    </Section>
  );
};

export default Process;
