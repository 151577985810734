import React, { useCallback, useState } from 'react';
import Button from './button';
import { ContactShadow } from '@/assets/shadow';
import useCreateLeads from '@/utils/useCreateLeads';
import { Container, Section } from '@/components/section';

const Contact: React.FC = () => {
  const { onSubmit } = useCreateLeads();
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleInputChange = useCallback(
    (
      event:
        | React.FormEvent<HTMLInputElement>
        | React.FormEvent<HTMLTextAreaElement>
    ) => {
      const { name, value } = event.currentTarget;
      setFormValues({ ...formValues, [name]: value });
    },
    [formValues]
  );

  return (
    <Section id="js-contact">
      <Container className="relative max-w-[1280px]">
        {/* <picture className="pointer-events-none absolute inset-x-0 -z-20">
          <ContactShadow className="h-auto w-full" />
        </picture> */}
        <picture className="pointer-events-none absolute -z-10 hidden opacity-80 sm:bottom-16 sm:left-0 sm:block lg:bottom-36 lg:left-16">
          <video autoPlay={true} loop muted className="h-auto w-[45%]">
            <source src="/assets/video/bg_contact.mp4" type="video/mp4" />
          </video>
        </picture>
        <div className="z-10 w-full justify-center sm:flex" id="contact">
          <h3 className="mb-8 text-2xl font-medium tracking-tighter sm:hidden sm:text-[40px]">
            Contact Form
          </h3>
          <div className="sm:mr-6 sm:mt-16 lg:-mr-8 lg:mt-0">
            <div className="rounded-[36px] border border-sky/50 bg-darker/40 p-4 px-7 py-11 lg:rounded-[72px] lg:px-16 lg:py-14">
              <Button className="gradient-button-bg-1 px-8 text-sm font-light tracking-[-0.065em] lg:whitespace-nowrap lg:text-[20px] xl:text-[26px]">
                Nestflo
              </Button>
              <div className="mt-8 space-y-2 font-light tracking-tighter lg:text-[28px]">
                <p>
                  <span className="mr-2 text-[#CD3E41]">Email:</span>
                  Hello@nestflo.com
                </p>
                <p>
                  <span className="mr-2 text-[#CD3E41]">Tel:</span>03301338626
                </p>
              </div>
              <div className="mt-6 space-y-3 font-light">
                <p>Unit 111, Filwood Green Business Park,</p>
                <p>1 Filwood Park Lane,</p>
                <p>Bristol, BS4 1ET,</p>
                <p>United Kingdom</p>
              </div>
            </div>
          </div>
          <div>
            <div className="relative my-7 pl-24 sm:pl-7 lg:my-14 lg:mt-0 lg:pl-24">
              <picture className="pointer-events-none absolute left-[-120px] top-[-95px] -z-10 opacity-80 sm:hidden">
                <video autoPlay={true} loop muted className="h-auto w-[300px]">
                  <source src="/assets/video/bg_contact.mp4" type="video/mp4" />
                </video>
              </picture>
              <h3 className="mb-4 hidden text-2xl font-medium tracking-tighter sm:block sm:text-[40px] lg:text-[48px] lg:leading-[70px] xl:text-[60px]">
                Contact Form
              </h3>
              <p className="text-sm font-thin lg:text-xl lg:leading-[36px]">
                Please leave your details below and we will contact you within
                24 hours.
              </p>
            </div>

            <div>
              <div className="rounded-[36px] border border-sky/50 bg-darker/40 p-4 pb-10 lg:rounded-3xl lg:px-24 lg:pb-16 lg:pt-28">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    try {
                      onSubmit(formValues);
                    } catch (error) {
                      // eslint-disable-next-line no-console
                      console.log(error);
                    } finally {
                      setFormValues({
                        name: '',
                        email: '',
                        phone: '',
                        message: ''
                      });
                    }
                  }}
                >
                  <div className="mx-auto w-full">
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                      <div>
                        <label
                          htmlFor="name"
                          className="block font-light leading-6 lg:text-xl"
                        >
                          Name<span className="text-orange">*</span>
                        </label>
                        <div className="mt-3">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={formValues.name}
                            onChange={handleInputChange}
                            className="w-full min-w-0 appearance-none rounded-2xl border border-[#4906D0]/60 bg-darker/20 px-8  py-2 text-base text-white placeholder:text-[#6523EF]"
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="mobile-number"
                          className="block font-light leading-6 lg:text-xl"
                        >
                          Phone number<span className="text-orange">*</span>
                        </label>
                        <div className="mt-3">
                          <input
                            type="number"
                            name="phone"
                            id="mobile-number"
                            value={formValues.phone}
                            onChange={handleInputChange}
                            className="w-full min-w-0 appearance-none rounded-2xl border border-[#4906D0]/60 bg-darker/20 px-8  py-2 text-base text-white placeholder:text-[#6523EF]"
                            required
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="email"
                          className="block font-light leading-6 lg:text-xl"
                        >
                          Email<span className="text-orange">*</span>
                        </label>
                        <div className="mt-3">
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={formValues.email}
                            onChange={handleInputChange}
                            className="w-full min-w-0 appearance-none rounded-2xl border border-[#4906D0]/60 bg-darker/20 px-8  py-2 text-base text-white placeholder:text-[#6523EF]"
                            required
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="message"
                          className="block font-light leading-6 lg:text-xl"
                        >
                          Message<span className="text-orange">*</span>
                        </label>
                        <div className="mt-3">
                          <textarea
                            name="message"
                            id="message"
                            rows={4}
                            value={formValues.message}
                            onChange={handleInputChange}
                            className="w-full min-w-0 resize-none appearance-none rounded-2xl border border-[#4906D0]/60 bg-darker/20 px-8  py-2 text-base text-white placeholder:text-[#6523EF]"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-8 flex justify-center">
                      <Button className="bg-gradient-to-r from-blue via-brown to-orange px-14">
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="mt-8  hidden flex-col sm:items-end lg:px-8">
                <img
                  src="/logo/logo_full.png"
                  alt="Nestflo"
                  className="w-[120px] lg:w-[190px]"
                />
                <ul className="flex items-center space-x-6 lg:justify-end">
                  <li>
                    <a
                      href="/assets/documents/terms-conditions.pdf"
                      target="_blank"
                      className="font-light text-white hover:text-orange lg:text-lg"
                    >
                      Website T&C
                    </a>
                  </li>
                  <li>
                    <a
                      href="/assets/documents/privacy-policy.pdf"
                      target="_blank"
                      className="font-light text-white hover:text-orange lg:text-lg"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/assets/documents/cookie-policy.pdf"
                      target="_blank"
                      className="font-light text-white hover:text-orange lg:text-lg"
                    >
                      Cookie policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Contact;
